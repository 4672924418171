import React, { useState, useEffect, useRef } from 'react';

import {  Link } from 'react-router-dom';
import {  motion } from "framer-motion";
//import { Media } from 'react-breakpoints';
import {Helmet} from "react-helmet";

import anime from "animejs";
import Moment from 'react-moment';

/*

Local

*/

import {Ticker} from './components/Ticker.js';
import {StackGallery} from './components/StackGallery.js';
import {AnimatedText} from './components/AnimatedText.js';
import {PrismicAPI} from './components/Prismic.js';
import {TouchClass} from './components/IsTouch.js';
import {Presence} from './components/Presence.js';
import {Sort} from './components/Sort.js';
import {Group} from './components/Group.js';
import {Burger} from './components/Burger.js';
import {Nav} from './components/Nav.js';
import {SmartCursor} from './components/SmartCursor.js';
import {Content} from './components/Content.js';
import {useBodyClass} from './hooks/useBodyClass.js';

/*

Store

*/

import {useStore,Store,StoreRouter} from './store';
import {routes} from './store/transformer.js';

/*

Pages

*/

import {Project} from './pages/Project.js';
import {Gallery} from './pages/Gallery.js';
import {Home} from './pages/Home.js';
import {Profile} from './pages/Profile.js';
import {Index} from './pages/Index.js';
import {Basic} from './pages/Basic.js';



/*

Styles

*/

import './App.scss';

/*

Assets

*/

import logo from './assets/Logo-white.svg';
import logoBlack from './assets/Logo-black.svg';

function BodyClass({className}){

    useBodyClass(className);

    return <div />

}






function Meta({data}){

  if(!data) return null;

  let matchKey = Object.keys(data).filter((k)=>(window.location.href.match(k))).pop();
  if(!matchKey) return null;

  let metaData = data[matchKey];

  return <Helmet>
           
            <title>{metaData.title}</title>
            <meta
            name="description"
            content={metaData.description}  />

        </Helmet>


}


function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}


function App() {

  const {setCursor,unsetCursor} = useStore();
  const setDataLoaded = useStore(state => state.setDataLoaded);
  const isDataLoaded = useStore(state => state.loaded);
  const size = useWindowSize();
  const scroller = useRef();

  return (
    <TouchClass>

      <PrismicAPI
     endpoint={`https://here-talks.prismic.io/api/v2`}
     loader={()=> <div />}
     onLoad={({docs})=>{
      
      // Update the store
      setDataLoaded(docs);

    }}>

      {({isLoaded,docs}) => {



        if(!isDataLoaded) return <BodyClass className={`Body--loading`} />
        else return (

          <StoreRouter routes={routes}>
          <Store>
            {
              (store) => {
            
                if(!store.ENTRY) return null;

                return (
                <div>

                <BodyClass className={`Body--${store.ENTRY.type}`} />

                 

                <div className="Page">
                <div className="Page-scroll" ref={scroller}>


               <div className="Intro" style={{height:size.height}}>
                <div className="Intro-foot">
                  <ul className="List List--nav">
                    
                    <li><Link to={'/info/'}>Info</Link></li>
                    <li><a href="https://www.londoncraftweek.com/events/here-talks-with-here-design/" target="_blank" rel="nofollow">Tickets</a></li>
                    <li><a href="https://goo.gl/maps/Yea3dTAbFcJLcfvTA" target="_blank" rel="nofollow">Map</a></li>
                  </ul>
                </div>
                  <h1 className='Intro-title'><img alt="Here Talks" src={logo} /></h1>
                  <div className="Intro-gallery" onClick={
                ()=>{
                    if(scroller.current){
                    anime({
                      targets: scroller.current,
                      scrollTop: window.innerHeight,
                      duration: 1000,
                      easing: 'easeOutQuint'
                    });
                      unsetCursor();
                    }

                }
              }
              onMouseEnter={()=>{
                setCursor('down');
              }}
              onMouseLeave={()=>{
                unsetCursor();
              }}>

                    <StackGallery items={store.HOME.gallery}>
                      {
                        (i) => (<div className="Intro-media"><img src={i.image.url} alt={`${store.HOME.title}`} /></div>)
                      }
                    </StackGallery>
                    {
                      /*store.HOME.gallery.map(i => <div className="Intro-media"><img src={i.image.url} alt={`${store.HOME.title}`} /></div>)*/
                    }
                  </div>

                 

               <div className="Intro-head">
               <Ticker direction={'toLeft'}>
                <ul className="List List--ticker">
                  <li><span>ILLUMINATING</span> <span>OUR</span> <span>PRACTICE</span> <span>OF</span> <span>CRAFT</span> <span>AND</span> <span>COLLABORATION</span></li>
                  <li><span>7 - 9</span> <span>OCTOBER</span> <span>2021</span></li>
                  <li><span>HOXTON</span> <span>GALLERY</span></li>
                  <li><span>E2 7JN</span></li>
                </ul>
               </Ticker>
               </div>

                </div>

              {/* Info */}

              <div className="Page--project">
              <div className="Page-belowFold">
              <div className="Page-content">
                <Content content={store.ENTRIES.find(e=>(e.type === 'info')).body} />
              </div>
              <div className="Page-detail">
                <div className="Content">
                  <h3>About the Talks</h3>

                  <dl>
                    <li>
                      <dt>When</dt>
                      <dd>
                        <Moment format="dddd, Do">
                           2021-10-07
                        </Moment> until <Moment format="dddd, Do MMMM YYYY">
                           2021-10-09
                        </Moment>
                      </dd>
                    </li>
                    <li>
                      <dt>Where</dt>
                      <dd>Hoxton Gallery, 17 Marlow Workshops, E2 7JN<br /><a href="https://goo.gl/maps/Yea3dTAbFcJLcfvTA" target="_blank" rel="nofollow">Map</a></dd>
                    </li>
                    <li>
                      <dt>Tickets</dt>
                      <dd>Limited availability<br />See <a href="https://www.londoncraftweek.com/events/here-talks-with-here-design/" target="_blank" rel="nofollow">londoncraftweek.com</a></dd>
                    </li>
                  </dl>

                  
                </div>
              </div>
              </div>
              </div>
              {/* End Info */}

               
                <Sort dir='asc' prop={'date'} items={store.ENTRIES.filter(e=>(e.type === 'project'))}>{
                  ({items}) => {

                    return  (

                      <Group prop={'date'} items={items}>{
                         ({items}) => {

                          return items.map(group => {

                            return (<div>
                              <h3 className="Site-index-heading"><Moment format="dddd, Do MMMM YYYY">
              {group[0].date}
            </Moment></h3>
                            <Sort dir='asc' prop={'time'} items={group}>{
                             ({items}) => {
                              return <Index projects={items} />
                             }}
                            </Sort>

                            </div>)

                          })

                        }}
                      </Group>
                          
                      
                    )

                  }
                }</Sort>
                </div>
                </div>

               
               <Presence className="Layer Layer--project" isVisible={store.SHOWPROGRAMME}>
                  <Basic>
                    <div className="Page-blocks">

                      <h2>Programme</h2>
                      <Sort dir='asc' prop={'date'} items={store.ENTRIES.filter(e=>(e.type === 'project'))}>{
                  ({items}) => {

                    return  (

                      <Group prop={'date'} items={items}>{
                         ({items}) => {

                          return items.map(group => {

                            return (
                            <div>

                              <h3 className="Site-index-heading">
                              <Moment format="dddd, Do MMMM YYYY">
                                {group[0].date}
                              </Moment>
                              </h3>
                              <div className="Site-programme">
                            <ul className="List List--programme">
                            <Sort dir='asc' prop={'time'} items={group}>{
                             ({items}) => {
                              return items.map(item => (
                                <li>
                                <Link to={item.uri} className="Item Item--programme">
                                 
                                  <div className="Item-body">
                                  <div className="Item-media">
                                    <img src={`${item.image.url}&w=600`} alt={item.title} />
                                  </div>
                                  <div className="Item-content">
                                    <div className="Item-title">{item.title}</div>
                                     <div className="Item-time">{item.time}</div>
                                    <div className="Item-text"><Content content={item.summary} /></div>
                                  </div>
                                  </div>
                                </Link>
                                </li>
                              ))
                             }}
                            </Sort>
                            </ul>
                            </div>
                            </div>)

                          })

                        }}
                      </Group>
                          
                      
                    )

                  }
                }</Sort>
                    </div>
                  </Basic>
                </Presence>

                <Presence className="Layer Layer--project" isVisible={store.SHOWINFO}>
                  <Basic>
                    <div className="Page-blocks">

                      <div className="Page-aboveFold">
                      <div className="Page-body">

                        <div className="Page-logo">
                          <img src={logoBlack} alt="Here Talks" />
                        </div>

                        <div className="Page-content">
                          <Content content={store.ENTRIES.find(i=>i.type === 'info').body} />
                        </div>
                        <div className="Page-detail">
                          <div className="Content">
                            <h3>About the Talks</h3>

                            <dl>
                              <li>
                                <dt>When</dt>
                                <dd>
                                  <Moment format="dddd, Do">
                                     2021-10-07
                                  </Moment> until <Moment format="dddd, Do MMMM YYYY">
                                     2021-10-09
                                  </Moment>
                                </dd>
                              </li>
                              <li>
                                <dt>Where</dt>
                                <dd>Hoxton Gallery, 17 Marlow Workshops, E2 7JN<br /><a href="https://goo.gl/maps/Yea3dTAbFcJLcfvTA" target="_blank" rel="nofollow">Map</a></dd>
                              </li>
                              <li>
                                <dt>Tickets</dt>
                                <dd>Limited availability<br />See <a href="https://londoncraftweek.com" target="_blank" rel="nofollow">londoncraftweek.com</a></dd>
                              </li>
                            </dl>

                            
                          </div>
                        </div>
                        {/* End detail */}
                        </div>
                      </div>
                    {/* End above fold */}
                      <div className="Page-belowFold">
                        <Sort dir='asc' prop={'date'} items={store.ENTRIES.filter(e=>(e.type === 'project'))}>{
                  ({items}) => {

                    return  (

                      <Group prop={'date'} items={items}>{
                         ({items}) => {

                          return items.map(group => {

                            return (
                            <div>

                              <h3 className="Site-index-heading">
                              <Moment format="dddd, Do MMMM YYYY">
                                {group[0].date}
                              </Moment>
                              </h3>
                              <div className="Site-programme">
                            <ul className="List List--programme">
                            <Sort dir='asc' prop={'time'} items={group}>{
                             ({items}) => {
                              return items.map(item => (
                                <li>
                                <Link to={item.uri} className="Item Item--programme">
                                 
                                  <div className="Item-body">
                                  <div className="Item-media">
                                    <img src={`${item.image.url}&w=600`} alt={item.title} />
                                  </div>
                                  <div className="Item-content">
                                    <div className="Item-title">{item.title}</div>
                                     <div className="Item-time">{item.time}</div>
                                    <div className="Item-text"><Content content={item.summary} /></div>
                                  </div>
                                  </div>
                                </Link>
                                </li>
                              ))
                             }}
                            </Sort>
                            </ul>
                            </div>
                            </div>)

                          })

                        }}
                      </Group>
                          
                      
                    )

                  }
                }</Sort>
                      </div>
                    </div>
                  </Basic>
                </Presence> 

                <Presence className="Layer Layer--project" isVisible={store.ENTRY.type === 'project'}>
                  <Project header={

                    <Ticker direction={'toLeft'}>
                    <ul className="List List--ticker">
                      {store.ENTRY.booking_title && <li>{store.ENTRY.booking_title.split(' ').map(i => (<><span>{i}</span><span> </span></>))}</li>}
                      <li>{store.ENTRY.title.split(' ').map(i => (<><span>{i}</span><span> </span></>))}</li>
                    </ul>
                   </Ticker>

                  } entry={store.ENTRY} />
                </Presence> 

                 <SmartCursor state={store.cursorState} active={store.cursorActive} />


                </div>
                )
              }
            }
          </Store>

          </StoreRouter>

        )

      }}
    </PrismicAPI>
    
    </TouchClass>
  );
}



export default App;
