import React , {useEffect,useState,useRef} from 'react'
import {useStore,Store,StoreRouter} from '../store';
import {  NavLink, Link, useHistory, useLocation} from 'react-router-dom';
import {Content} from '../components/Content.js';
import {StackGallery} from '../components/StackGallery.js';
import {AnimatedText} from '../components/AnimatedText.js';
import anime from "animejs";
import Moment from 'react-moment';

import { GrContract,GrFormPreviousLink,GrClose,GrUndo,GrFormClose } from "react-icons/gr";

import {
  motion,
  useElementScroll
} from "framer-motion"

import {useBodyClass} from '../hooks/useBodyClass.js';

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function Slice({slice}){

    switch(slice.slice_type){

        case 'text':
        return <TextSlice slice={slice} />

        case 'media':
        return <MediaSlice slice={slice} />

        default:
        return null;

    }

}

function TextSlice({slice}){

  return <div className="Blocks"><div className={`Block Block--text`}><Content content={slice.primary.text}/></div></div>

}

function MediaSlice({slice}){

  let style = 'full';
  if(slice.primary.style) style = slice.primary.style.toLowerCase();

  return <div className={`Blocks Blocks--${style}`}>
  <div className="Blocks-grid">
  {slice.items.map((i,ix)=>(
    <div className={`Block Block--media Block--size-${i.size.replace(' ','-').toLowerCase()}`}>
    <div className="Block-media">
      <Media item={i} />
    </div>
    <div className="Block-caption"><span>{i.caption}</span></div>
    </div>
  ))}
  </div>
  </div>

}

function ActiveTile({media}){

 return <div className={`Blocks Blocks--full`}>
  <div className="Blocks-grid">
    <div className={`Block Block--media Block--size-full`}>
    <div className="Block-media">
      <ActiveMedia media={media} />
    </div>
    </div>
  ))}
  </div>
  </div>

}

function ActiveMedia({media}){

  
  if(media.url.match('.mp4')){
    return <video 
            src={media.url}
            initial={false}
            autoPlay={true}
            controls={false}
            playsInline={true}
            muted={true}
            loop={true}
           />
  }else{
    return <img src={`${media.url}&w=1200`} />
  }
  

}

function Media({item}){
  return <img src={`${item.image.url}&w=1200`} />
}

export const Project = ({header=null,entry}) => {

      let history = useHistory();
      const completed = useRef(false);
      const ref = useRef()
      const { scrollY } = useElementScroll(ref);
      const [opacity,setOpacity] = useState(1);

      const {setCursor,unsetCursor} = useStore();
      const size = useWindowSize();

      const screenRef = useRef()
      const [blackout,setBlackout] = useState(1);

      
      //const ACTIVE_TILE = useStore(state => state.ACTIVE_TILE);

      const HISTORY = useStore(state => state.HISTORY);
      const store = useStore(state => state);

      let previousListingPage = HISTORY.find(e=>(['profile','project'].indexOf(e.type) === -1));
      let backTo = previousListingPage ? previousListingPage.href : '/';


      scrollY.onChange(()=>{
        
        // Heading disappears after 200px scroll
        let o = 1 - (scrollY.current / 200);
        if(o < 0) o = 0;

        if(o !== opacity) setOpacity(o);

        let maxScroll = ref.current.scrollHeight - ref.current.clientHeight;
        let startAt = maxScroll - screenRef.current.offsetHeight;

        startAt += 0;

        let dist = maxScroll - startAt;

        if(scrollY.current >= startAt){
          let blackoutProgress = (scrollY.current - startAt) / dist;
          let b = 1 - (blackoutProgress);

          if(b !== blackout) setBlackout(b);

          // On complete...
          if((blackoutProgress >= 0.98) && (!completed.current)){
            // Update the ref
            completed.current = true;
            // Go back a page
            history.push(backTo);

          }

        }

      })
      

      

      return <div className="Page Page--project">

      <Link className="Page-close" to={backTo}><GrFormClose /></Link>

      
      <div className="Page-scroll" ref={ref}>
        <div className="Page-paper" >
          <div className="Page-blocks" >


          <div className="Intro" style={{height:size.height}}>
                <div className="Intro-head">
                  {header || null}
                </div>
                  <h1 className='Intro-title'><AnimatedText text={store.ENTRY.title} /></h1>
                  <div className="Intro-gallery"onClick={
                ()=>{
                    if(ref.current){
                    anime({
                      targets: ref.current,
                      scrollTop: window.innerHeight,
                      duration: 1000,
                      easing: 'easeOutQuint'
                    });
                      unsetCursor();
                    }

                }
              }
              onMouseEnter={()=>{
                setCursor('down');
              }}
              onMouseLeave={()=>{
                unsetCursor();
              }}>

                    <StackGallery items={entry.gallery}>
                      {
                        (i) => (<div className="Intro-media"><img src={`${i.image.url}&w=1200`} alt={`${entry.title}`} /></div>)
                      }
                    </StackGallery>
                    {
                      /*store.HOME.gallery.map(i => <div className="Intro-media"><img src={i.image.url} alt={`${store.HOME.title}`} /></div>)*/
                    }
                  </div>

                  <div className="Intro-foot">
                    <ul className="List List--nav">
                      <li style={{cursor:'pointer'}} onClick={
                        ()=>{
                            if(ref.current){
                            anime({
                              targets: ref.current,
                              scrollTop: window.innerHeight,
                              duration: 1000,
                              easing: 'easeOutQuint'
                            });
                              unsetCursor();
                            }

                        }
                      }>Details</li>
                    </ul>
                  </div>
              
               </div>
            
            <div className="Page-belowFold">
            <h1 className="Page-title">{entry.title} — {entry.booking_title}</h1>

            <div className="Page-body">
              <div className="Page-content">
              <Content content={entry.description} />
              </div>
              <div className="Page-detail">
                <div className="Content">
                  <h3>About the Talk</h3>

                  <dl>
                    <li>
                      <dt>When</dt>
                      <dd><Moment format="dddd, Do MMMM YYYY">
                 {entry.date}
              </Moment><br />{entry.time}</dd>
                    </li>
                    <li>
                      <dt>Where</dt>
                      <dd>Hoxton Gallery, 17 Marlow Workshops, E2 7JN<br /><a href="https://goo.gl/maps/Yea3dTAbFcJLcfvTA" target="_blank" rel="nofollow">Map</a></dd>
                    </li>
                    <li>
                      <dt>Tickets</dt>
                      <dd>“{entry.booking_title}”<br /><br />Limited availability<br />See <a href="https://www.londoncraftweek.com/events/here-talks-with-here-design/" target="_blank" rel="nofollow">londoncraftweek.com</a></dd>
                    </li>
                  </dl>

                  {!!entry.links.length && <h3>More Information</h3>}

                  <dl>
                    {
                      entry.links.map(link => {

                        let linkText = link.url.replace('www.','').replace(/\/$/,'').replace(/https?:\/\//,'').replace('instagram.com/','@');

                        return (
                             <li>
                              <dt>{link.label}</dt>
                              <dd><a href={link.url} target="_blank" rel="nofollow">{linkText}</a></dd>
                              </li>
                        )
                      })
                    }
                  </dl>
                </div>
              </div>
             {/*<div className="Page-audio">
            <audio controls src={entry.audio.url}></audio>
            </div>*/}
            {/*<div className="Page-media" style={{backgroundImage:`url(${entry.image.url}&w=1200)`}}>
              </div>*/}
            </div>
            </div>
            {/* End Below Fold */}
          </div>
        </div>
        <div className="Page-blackout" style={{ opacity: blackout }} ref={screenRef} />
      </div>

      </div>
}
